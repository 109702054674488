h1,
h2 {

	font-size: 32px;
	color: #000; 
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 0px;
	line-height: 32px;

}

h3 {
	
	font-size: 24px;
	color: #595959; 
	font-weight: 100;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 10px;

}


p {

	color: #000;
	font-weight: 200;
}

.c-pseudo-h1 {

	font-size: 32px;
	font-weight: 700;
	line-heigh: 30px;
	color: #fff;
	display: block;
}

.text-seo {
	font-size: 12px;
	color: #d7d7d7;
	display: block;
	line-heigh: 15px;
}