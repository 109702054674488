.button.rounded{

	border-radius: 50px;
	display: block;
	margin: 0 auto;
	text-transform: uppercase;
	font-size: 18px;
	border-color: #EB6F1D;
	color: #EB6F1D;
	padding: 15px 30px;

	&:before {
		content: '+';
		color: #000;
		font-size: 32px;
		margin-right: 10px;
		line-height: 10px;
		font-weight: 100;
	}

}
.button.rounded-close{

	border-radius: 50px;
	display: block;
	margin: 0 auto;
	text-transform: uppercase;
	font-size: 18px;
	border-color: #EB6F1D;
	color: #EB6F1D;
	padding: 15px 30px;

	&:before {
		content: '+';
		transform: rotate(45deg);
		color: #000;
		font-size: 32px;
		margin-right: 10px;
		line-height: 10px;
		font-weight: 100;
		display: inline-block;
	}

}