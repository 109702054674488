.o-section{

	padding-top: 60px;


	&--dark{
		background-color: #000; 
		padding-top: 60px;
		padding-bottom: 40px;
		h2 {
			color: #fff;
		}
		h3{
			color: #fff;
		}
		span {

			color: #fff;
		}

	}
	&--orange {
		background-color: #EB6F1D;
		padding-top: 25px;
		padding-bottom: 15px;
		
		p {

			color: #fff;
		}

		h2 {

			color: #fff;
		}

		h3 {

			color: #fff;
		}
	}
	&--footer{
		padding: 30px 0px;
	}
	&--form {
		margin-bottom: 30px;
	}
}


.border-bottom--orange {
	padding-bottom: 20px;
	border-bottom: 2px solid #EB6F1D;
	background-color: #efefef;
}