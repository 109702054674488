
.c-navigation {
	width: 100%;
	background-color: #fff;
	z-index: 999;
	position: fixed;
	-webkit-box-shadow: 0px 16px 20px -10px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 16px 20px -10px rgba(0,0,0,0.3);
	box-shadow: 0px 16px 20px -10px rgba(0,0,0,0.3);

	&--container {
		padding: 10px 0;
		@include breakpoint (large){
			padding: 20px 0px;
		}
		

		li a {

			color: #EB6F1D;
			font-family: 'Roboto', sans-serif;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 500;
			display: none;


			@include breakpoint (large) {
				display: block;
			}
		}
	}

	&-logo {

	
		&-link {
			margin-right: 8px;
			padding: 0px !important;
			width: 90%;
			text-align: center;

			@include breakpoint (large) {
				width: 150px;
			}
		}
		
		&--img {
			width: 150px;
		}
		@include breakpoint (large) {
			display: block;
		}
	}
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
	top: 60%;
	right: auto;
	left: 17px;

}

.is-dropdown-submenu {
	border: none;
	background-color: rgba(102, 204, 255, 0.5);

	li a {

		color: #fff;
	}
}
.menu-icon {
	margin-top: 12px;
}