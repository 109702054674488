.o-info-tile {
	border: 1px solid #ccc;
	border-radius: 10px;
	width: 100%;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 20px;

	&--image {

		width: 100%;

		&-reveal {
			display: block;
			margin: 0 auto;
			width: 100%;
			margin-bottom: 25px;
		}
	}

	&--inner {

		padding: 20px 15px;

		p {

			color: #595959;
			font-size: 14px;
			font-weight: 200;
		}
	}
	&--headline {
		font-weight: 400;
		font-size: 24px;
		text-align: left;
		margin-bottom: 10px;
	}
}
.o-service {
	padding-top: 20px;
	padding-bottom: 60px;
	&-tile {

		border: 1px solid #d7d7d7;
		border-radius: 10px;
		background-color: #EB6F1D;
		-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		overflow: hidden;
		margin-bottom: 20px;
		
		&--title {
			font-family:'Roboto';
			font-weight: 500;
			font-size: 16px;
			padding-top: 15px;
			padding-left: 15px;
		}

		&--description {
			font-family:'Roboto';
			font-weight: 300;
			font-size: 12px;
			line-height: 15px;
			display: block;
			padding-bottom: 15px;
			padding-left: 15px;
		}

		&--price {

			background-color: #EB6F1D;
			color: #fff;
			font-weight: 700;
			height: 100%;

		}

		hr {
			border-bottom: 1px solid #d7d7d7;
			margin-bottom: 5px;
		}
	}
}

.o-product {
	padding-top: 60px;
	padding-bottom: 60px;

	p {
		margin-bottom: 30px;
	}


	&-tile {

		border: 1px solid #d7d7d7;
		border-radius: 10px;
		background-color: #fff;
		-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		overflow: hidden;
		margin-bottom: 20px;

		img {
			
			margin-bottom: 15px;
			width: 100%;
			height: auto;
		}
		
		&--title {
			font-family:'Roboto';
			font-weight: 500;
			font-size: 16px;
			padding-top: 15px;
			padding-left: 15px;
		}

		&--description {
			font-family:'Roboto';
			font-weight: 300;
			font-size: 12px;
			line-height: 15px;
			display: block;
			padding-bottom: 15px;
			padding-left: 15px;
		}

		&--price {

			color: #EB6F1D;
			text-align: right;
			padding-bottom: 15px;
			padding-right: 15px;
		}

		hr {
			border-bottom: 1px solid #d7d7d7;
			margin-bottom: 5px;
		}
	}
}
