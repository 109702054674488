
// Orbit Slider
.orbit {

	padding-top: 40px;

	@include breakpoint (large) {

		padding-top: 90px;
	}
}

.orbit-wrapper {
	height: 90vh;
	overflow: hidden;
}

.orbit-caption {
	top: 10% !important;
	right: 20% !important;
	width: 200px !important;
	height: 200px !important;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	border-radius: 100%;
	font-size: 32px !important;
	line-height: 32px !important;

	@include breakpoint (small) {
		background-color: transparent;
		font-size: 24px;
		line-height: 20px;
	}

	@include breakpoint (medium) {
		background-color: #EB6F1D;
		font-size: 24px;
		line-height: 25px;
		height: 50px;

	}

	@include breakpoint (large) {
		
		font-size: 42px;
		top: 50%;
		height: 75px;
		line-height: 50px;
	}
	
	&--2 {
		top:20% !important;
		right:10% !important;
		width: 150px !important;
		height: 150px !important;
		opacity: 0.5;

	}
}


// Menu icon

.menu-icon:after {

	background-color: #EB6F1D;
	box-shadow: 0 7px 0 #EB6F1D, 0 14px 0 #EB6F1D;
}

//HR

hr {

	border-bottom: 2px solid #EB6F1D;
	margin-top: 0px;
}

.white {
	background-color: #fff;
}