.c-reveal--image {

	width: 100%;


	&-preview {
		width: 100%;

		&-button {

			display: block;
			width: 100%;
		}
		&-galery {
			display: flex;
		}
	}
}