.c-background-teaser {
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: initial;
	background-size: cover;
	position: relative;

	span {

		font-family: 'Roboto';
		color: #fff;
		position: absolute;

		
		@include breakpoint (medium) {
			font-size: 100px;
			line-height: 100px;
			font-weight: 700;
			bottom: 20%;
			left: 10%;
			width: 60%;

		}

		@include breakpoint (small) {
			font-size: 50px;
			line-height: 50px;
			font-weight: 700;
			bottom: 20%;
			left: 10%;
			width: 60%;

		}
	}

	&--1 {
		background-image: url("../img/190221_KoeWash_B7C5529.jpg");
	}
}