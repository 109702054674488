.o-review-container {

	background-color: #fff;
	position: relative;
	max-width: 60rem;
	margin: 0 auto;

	&--inner {
		
		padding: 60px 50px 10px 50px;
	}

	&:after {
		content: '';
		left: 0%;
		bottom: 100%;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 50px 50px 0px;
		border-color: transparent transparent #ffffff transparent;
	}

	&--rater {
		float: right;
		margin-right: -10px;
    	margin-top: -30px;

		&:after {

			clear: both;
		}
	}

}